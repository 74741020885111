import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'future-card',
  templateUrl: './future-card.component.html',
  styleUrls: ['./future-card.component.scss']
})
export class FutureCardComponent implements OnInit {

  @Input() title: string = '{{title}}'
  @Input() description: string = '{{description}}'
  @Input() cardImg: string = ''

  constructor() { }

  ngOnInit() {
  }

}
