import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  constructor() { }

  ngOnInit() {
    this.galleryOptions = [
      {
        width: '800px',
        height: '500px',
        thumbnailsColumns: 6,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        thumbnailsColumns: 4,
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.galleryImages = [
      {
        small: 'assets/img/gallery/activity-s.png',
        medium: 'assets/img/gallery/activity-m.png',
        big: 'assets/img/gallery/activity-l.jpg'
      },
      {
        small: 'assets/img/gallery/teaser-s.png',
        medium: 'assets/img/gallery/teaser-m.png',
        big: 'assets/img/gallery/teaser-l.jpg'
      },
      {
        small: 'assets/img/gallery/climate-road-s.jpg',
        medium: 'assets/img/gallery/climate-road-m.jpg',
        big: 'assets/img/gallery/climate-road-l.jpg'
      },
      {
        small: 'assets/img/gallery/greta-s.jpg',
        medium: 'assets/img/gallery/greta-m.jpg',
        big: 'assets/img/gallery/greta-l.jpg'
      },
      {
        small: 'assets/img/gallery/guy-s.jpg',
        medium: 'assets/img/gallery/guy-m.jpg',
        big: 'assets/img/gallery/guy-l.jpg'
      },
      {
        small: 'assets/img/gallery/council-letter-s.jpg',
        medium: 'assets/img/gallery/council-letter-m.jpg',
        big: 'assets/img/gallery/council-letter-l.jpg'
      },
      {
        small: 'assets/img/gallery/sunday-s.jpg',
        medium: 'assets/img/gallery/sunday-m.jpg',
        big: 'assets/img/gallery/sunday-l.jpg'
      },
      {
        small: 'assets/img/gallery/easter-s.jpg',
        medium: 'assets/img/gallery/easter-m.jpg',
        big: 'assets/img/gallery/easter-l.jpg'
      },
      {
        small: 'assets/img/gallery/landscape-s.jpg',
        medium: 'assets/img/gallery/landscape-m.jpg',
        big: 'assets/img/gallery/landscape-l.jpg'
      },
      {
        small: 'assets/img/gallery/beta-s.jpg',
        medium: 'assets/img/gallery/beta-m.jpg',
        big: 'assets/img/gallery/beta-l.jpg'
      },
      {
        small: 'assets/img/gallery/funpic-s.JPG',
        medium: 'assets/img/gallery/funpic-m.JPG',
        big: 'assets/img/gallery/funpic-l.JPG'
      },
      {
        small: 'assets/img/gallery/shirt1-s.jpg',
        medium: 'assets/img/gallery/shirt1-m.jpg',
        big: 'assets/img/gallery/shirt1-l.jpg'
      },
      {
        small: 'assets/img/gallery/shirt2-s.jpg',
        medium: 'assets/img/gallery/shirt2-m.jpg',
        big: 'assets/img/gallery/shirt2-l.jpg'
      },
      {
        small: 'assets/img/gallery/david-s.jpg',
        medium: 'assets/img/gallery/david-m.jpg',
        big: 'assets/img/gallery/david-l.jpg'
      },
    ];

  }
}
