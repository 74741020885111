Many adults are aware that our climate is warming globally - and that we need to do something about it right now to minimise it, and
<br>
hopefully reverse it. As environmentally conscious adults, we are frustrated that we
are not being effective in persuading our government
<br>
to take more action on climate change. Many groups have put in a considerable amount
				   
 of work in efforts to get the message across. They
 <br>
have had some 
successes, but are often ignored or discredited, and sometimes concessions won from Government
are reversed at a later stage.
<br>	   
Now, time is running out and we believe that many others feel the same way.
<br><br>
We believe that young people recognise the problems we are facing; they clearly understand the need for action,
are motivated to find solutions
<br>
and will rise to the challenge, for it is they who will inherit the World from us.

In an address to the United Nations Mr. Morrison stated he received ‘<i>many letters from children in Australia
concerned about their future</i>’ and ‘<i>I deeply respect their concerns and indeed I welcome their passion, especially
when it comes to the environment. My impulse is always to seek to respond positively and to encourage them. The
passion and aspiration
<br>
of the young must be respected and harnessed</i>.'
<a class="font-weight-bold" href="https://www.pm.gov.au/media/national-statement-united-nations-general-assembly">
    <u>Source</u>
</a>
<br><br>
Young people of pre-voting age are not able to express their opinions by voting, and are often not listened to,
or taken seriously.
<br>
We offer them a voice and the option of expressing their concerns by sending a postcard to
the Prime Minister. We believe that
<br>
young people may succeed where we adults are failing.