import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ac-8',
  templateUrl: './ac-8.component.html',
  styleUrls: ['./ac-8.component.scss']
})
export class Ac8Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
