<main>

  <!-- Header Text 
  <h1 class="gallery-header text-center font-weight-bold">
    Our Pictures
  </h1>
  <p class="text-center">Click To Zoom! </p>
  <hr class="seperator">-->

  <!-- Gallery -->
  <div class="gallery-container d-flex justify-content-center">
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
  </div>
  
</main>
