import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';

declare var jQuery: any

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})


export class MainComponent implements AfterViewInit, OnDestroy {

  constructor() { }

  ngAfterViewInit() {
    jQuery(document).ready(function(){ //Photos Gallery
         jQuery('[data-fancybox="images-single"]').fancybox({
          thumbs: {
            autoStart: true
        },
         });
     });
  }
ngOnDestroy() {
  jQuery(".fancybox").unbind('click.fb');
  // or maybe jQuery(".fancybox").off() to remove all bindings
}

}
