<main>

  <!-- Big Intro -->
  <div class="intro-header" class="overlay">
    <div class="container">
      <div class="row">
        <div class="text-center col-12 text-white font-montserrat text-shdow">
          <h4>
            ‘It is time to acknowledge the future of those who live today
          </h4>
          <h4>and the generations of tomorrow.’</h4>
        </div>
      </div>
    </div>
  </div>

  <!-- Intro Section -->
  <section class="intro-section">
    <div class="container">
      <div class="text-center">
        <h1 class="font-weight-bold text-center"><span class="secondary-txt-color">Hello there!</span></h1>
        <hr class="seperator">
        <span class="latofont graytxt">
          <p>
            Our Future Activity Book aims to reach out to the young people of Australia, to equip them with a means and a voice to have a say in their future.
            <br><br>
            We want to put the power into the hands of tomorrow’s generation!
            <br><br>
            Frustrated by the lack of responsibility shown by the government over climate change, we created our book as a 
            source for action. Educational and entertaining, each activity book also contains a Postcard: through the postcard, 
            people not yet of voting age are given the opportunity to express their concerns over man-made climate change - that 
            is driving global warming, directly to the Australian Prime Minister.      
          </p>
        </span>
      </div>
    </div>
  </section>

  <!-- Activity Book -->
  <section class="activity-book-section">

    <!-- Header -->
    <div class="giftbag-header text-center">
      <h1 class="font-weight-bold text-center">
        <span class="secondary-txt-color">Activity book</span>
      </h1>
      <hr class="seperator">
    </div>

    <div class="container">
      <div class="row align-items-center d-flex justify-content-center">
        <!-- Left -->
        <div class="col-lg-3 d-flex justify-content-center">
          <div class="d-inline-block justify-content-center">
            <img class="mw-100 activity-book-homeimg border-radius-6px"
            data-fancybox="images-single"
            href="assets/img/activity-book-home.jpg"
            data-thumb="assets/img/activity-book-home.jpg" 
            src="assets/img/activity-book-home.jpg" id="imgHover">
          </div>
        </div>
        <!-- Right -->
        <div class="col-lg-4 order-left-mobile">

          <p class="graytxt latofont text-center">
            Your <b>FREE</b> 16-Page Environmental Activity Book!
          </p>

          <div class="d-flex justify-content-center activity-dl-div">
            <a type="button" href="assets/downloads/ACTIVITY_BOOK.pdf" class="btn btn-primary font-weight-bold dlbtn-div">
              <img class="activty-dlbutton" src="assets/img/1.png">
              Download
            </a>
          </div>

        </div>
      </div>
    </div>

</section>

<!-- Postcard -->
<section class="more-info-section">
  <div class="container">
    <div class="row align-items-center">
      <!-- Left -->
      <div class="col-lg-7">
        <h1 class="font-weight-bold text-center"><span class="secondary-txt-color">Postcard</span></h1>
        <div>
          <hr class="seperator">
        </div>
        <p class="graytxt latofont">
          A clock face superimposed upon the world depicts the hands at ‘ten minutes to twelve’.
          <br><br>
          This demonstrates how, over time, our beautiful Earth with its natural wonder and beauty is coming under attack from
          the effects of man-made climate change. It also shows the fragility of our planet in this Universe.
        </p>

        <div class="d-inline m-1">

          <h5 class="text-center">Don't have a postcard? Download the letter instead.</h5>

          <div class="d-flex justify-content-center">
            <a type="button" onclick="_gaq.push(['_trackEvent','Download','PDF',this.href]);" href="assets/downloads/Letter_to_PM.pdf" class="btn btn-primary font-weight-bold dlbtn-div">
              <img class="activty-dlbutton" src="assets/img/1.png">
              Download
            </a>
          </div>

        </div>

        <blockquote class="otro-blockquote">
          The moment we decide to fulfil something, we can do anything.
          <span class="font-16">Greta Thunberg</span>
        </blockquote>
      </div>
      <!-- Right -->
      <div class="col-lg-5">
        <div class="d-flex justify-content-center padding-6px"> <!--  style="width: 463px; height: 284px;" -->
          <img class="mw-100 border-radius-6px"
            id="imgHover"
            src="assets/img/postcard_front.jpg" 
            href="assets/img/postcard_front.jpg" 
            data-thumb="assets/img/postcard_front.jpg" 
            data-fancybox="images-single">
        </div>
        <div class="d-flex justify-content-center padding-6px">
          <img id="imgHover" class="mw-100 border-radius-6px border-width-1px border-style-solid"
          src="assets/img/postcard_back.png" 
          href="assets/img/postcard_back.png" 
          data-thumb="assets/img/postcard_back.png" 
          data-fancybox="images-single">
        </div>
      </div>
    </div>
  </div>
</section>

<!-- David Letter -->
<section class="more-info-section">
  <div class="container">
    <div class="row align-items-center">
      
      <!-- Left -->
      <div class="col-lg-5">
        <div class="d-flex justify-content-center padding-6px"> <!--  style="width: 463px; height: 284px;" -->
          <img class="mw-100 border-radius-6px"
            id="imgHover"
            src="assets/img/david-letter-1.jpg" 
            href="assets/img/david-letter-1.jpg" 
            data-thumb="assets/img/david-letter-1.jpg" 
            data-fancybox="images-single">
        </div>
        <div class="d-flex justify-content-center padding-6px">
          <img id="imgHover" class="mw-100 border-radius-6px border-width-1px border-style-solid"
          src="assets/img/david-letter-2.jpg" 
          href="assets/img/david-letter-2.jpg" 
          data-thumb="assets/img/david-letter-2.jpg" 
          data-fancybox="images-single">
        </div>
      </div>

      <!-- Right -->
      <div class="col-lg-7">
        <h1 class="font-weight-bold text-center"><span class="secondary-txt-color">Letter Of Support</span></h1>
        <div>
          <hr class="seperator">
        </div>
        <p class="graytxt latofont text-center">
          Great letter of support from Sir David Attenborough!
        </p>

        <blockquote class="otro-blockquote fs-14">
          <p>
            Dear Jim Bird and Helen Moffitt,
            <br><br>
            Thank you very much for sending me a copy of your environmental Activity Book. I hope it is a great success.
            <br><br>
            As you know, I believe the shallow seas of the Queensland coast with all its reefs and islands is one of the greatest treasures in the natural world.      
          </p>      
        </blockquote>

        <blockquote class="otro-blockquote fs-14">
          <p>
            At the moment, they are in grave danger from environmental change. I wish you success in all the things you are doing to protect it at this crucial time.
            <br><br>
            Best wishes
            <br>
            David Attenborough            
          </p>
        </blockquote>

      </div>

    </div>
  </div>
</section>

<!-- NASA Graph -->
<section class="more-info-section">
  <div class="container">
    <div class="row align-items-center">

      <!-- Right -->
      <div class="col-lg-7 order-left-mobile">

        <h1 class="font-weight-bold text-center"><span class="secondary-txt-color">NASA Graph</span></h1>
        <div>
          <hr class="seperator">
        </div>
        <p class="graytxt latofont">
          Man-made climate change is happening due primarily to greenhouse gas emissions.
          The result is a rapid increase in global warming. 
          
          We must recognise this and do something about it 
          immediately - with short-term, mid-term and long-term strategies.
        </p>
        <blockquote class="otro-blockquote">
          Young people: They care. They know that this is the world that they’re going to 
          grow up in, that they’re going to spend the rest of their lives in.
          <span class="font-16">Sir David Attenborough</span>
        </blockquote>

      </div>

      <!-- Left -->
      <div class="col-lg-5">

        <div class="d-inline-block justify-content-center">
          <img class="mw-100 border-radius-6px"
            id="imgHover"
            src="assets/img/global-warming-graph.png" 
            href="assets/img/global-warming-graph.png" 
            data-thumb="assets/img/global-warming-graph.png" 
            data-fancybox="images-single">
          <p class="text-center">
            Graph Source:
            <br>
            <b>
              <a href="https://climate.nasa.gov/climate_resources/9/graphic-earths-temperature-record/">LINK</a>
            </b>
          </p>
        </div>
        
      </div>

    </div>
  </div>
</section>

  <!-- Donate -->
  <section class="donate-bg-cover" style="background-image: url(/assets/img/img03.jpg);">
    <div class="container donate-inner">
      <div class="text-center text-white">

        <!-- Header -->

        <h1 class="font-lato donate-txt-shadow">
          Support <span class="font-weight-bold secondary-txt-color">‘Our Future’</span> initiative!
        </h1>

        <!-- Description -->

        <p class="latofont donate-txt-shadow">
          Times are tough financially, but we greatly appreciate any donation you are able to make.
          <br>
          You can make a difference; your voice is a catalyst for creating 
          vital change to protect our Earth.
        </p>

        <!-- Donate Button -->
        <div class="donate-btn-div d-inline w-100">
          <div class="w-100 gofundme-donate-margin">
            <img src="assets/img/gfm.png">
          </div>
          <div class="w-100">
            <a href="https://www.gofundme.com/f/climate-change-showbag" class="donate-now-btn cursor-pointer color-white">
              Donate Now
            </a>
          </div>
          <!-- 
          <a href="https://www.gofundme.com/f/climate-change-showbag">
            <img class="mw-100 border-radius-6px" src="assets/img/fundmeBtn.png">
          </a>
          -->
          <!-- 
          <a href="https://www.gofundme.com/">
            <p class="text-center font-weight-bold">Click Here To Donate!</p>
          </a>-->
        </div>

      </div>
    </div>
  </section>

  <!-- Council Quote -->
  <section class="container council-section d-flex justify-content-center">
    <div class="w-75">
      <p class="latofont quotation text-center">
        Sunshine Coast Environment Council is proud to support this initiative and wish
        <br>
        the Our Future team every success with their project and commend them on
        <br>
        their desire to engage with the issue of climate change in a meaningful way.
      </p>
      <div class="d-flex justify-content-center">
        <img class="council-img" src="assets/img/council-custom-logo.jpg">
      </div>
    </div>
  </section>

  <!-- Information Accordian -->
  <section class="accordian-section">
    <div class="container">
      <div class="accordian-div" id="accordion">

        <accordion-card title="Current situation with Man-made climate change" id="acordA"><ac-1></ac-1></accordion-card>
        <accordion-card title="Australia’s Action Plan"                        id="acordB"><ac-2></ac-2></accordion-card>    
        <accordion-card title="Why involve young people?"                      id="acordC"><ac-3></ac-3></accordion-card>
        <accordion-card title="Why an activity book?"       id="acordD"><ac-4></ac-4></accordion-card>
        <accordion-card title="Our Action Plan"                                id="acordE"><ac-5></ac-5></accordion-card>
        <accordion-card title="Costings"                                       id="acordF"><ac-6></ac-6></accordion-card>
        <accordion-card title="What Happens Next?"                             id="acordG"><ac-7></ac-7></accordion-card>
        <accordion-card title="Our Story"                                      id="acordJ"><ac-10></ac-10></accordion-card>
        <accordion-card title="Must Reads"                                     id="acordH"><ac-8></ac-8></accordion-card>
        <accordion-card title="Acknowledgements"                               id="acordI"><ac-9></ac-9></accordion-card>
    
      </div>
    </div>
  </section>

  <!-- Footer -->
  <div class="footer-div">
    <div class="d-flex justify-content-center">
      <img 
        class="w-75"
        id="imgHover"
        src="assets/img/footer.jpg" 
        href="assets/img/footer.jpg" 
        data-thumb="assets/img/footer.jpg" 
        data-fancybox="images-single">
    </div>
  </div>

  <div class="d-none">
    <!-- Teaser -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/teaser-l.jpg" 
    data-thumb="assets/img/gallery/teaser-s.png">

    <!-- Climate -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/climate-road-l.jpg" 
    data-thumb="assets/img/gallery/climate-road-s.jpg">

    <!-- Greta -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/greta-l.jpg" 
    data-thumb="assets/img/gallery/greta-s.jpg">

    <!-- Guy -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/guy-l.jpg" 
    data-thumb="assets/img/gallery/guy-s.jpg">

    <!-- Council -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/council-letter-l.jpg" 
    data-thumb="assets/img/gallery/council-letter-s.jpg">

    <!-- Sunday -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/sunday-l.jpg" 
    data-thumb="assets/img/gallery/sunday-s.jpg">

    <!-- Easter -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/easter-l.jpg" 
    data-thumb="assets/img/gallery/easter-s.jpg">

    <!-- Landscape -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/landscape-l.jpg" 
    data-thumb="assets/img/gallery/landscape-s.jpg">

    <!-- Beta -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/beta-l.jpg" 
    data-thumb="assets/img/gallery/beta-s.jpg">

    <!-- Fun Pic -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/funpic-l.JPG" 
    data-thumb="assets/img/gallery/funpic-s.JPG">

    <!-- Shirt1 -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/shirt1-l.jpg" 
    data-thumb="assets/img/gallery/shirt1-s.jpg">

    <!-- Shirt2 -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/shirt2-l.jpg" 
    data-thumb="assets/img/gallery/shirt2-s.jpg">

    <!-- David -->
    <img data-fancybox="images-single"
    href="assets/img/gallery/david-l.jpg" 
    data-thumb="assets/img/gallery/david-s.jpg">
  </div>

  <!-- Team 
  <section class="team-section">
    <div class="container">
      <div class="row d-flex justify-content-center">

        <app-team-card class="col-xs-6 col-md-3 col-lg-3"
          title="Helen Moffit"
          description="The brains behind the organization, be scared"
          image="member1.jpg">
        </app-team-card>

        <app-team-card class="col-xs-6 col-md-3 col-lg-3"
        title="Jim"
        description="Doesn't have a last name, Jim is his full name."
        image="member2.jpg">
      </app-team-card>

      </div>
    </div>
  </section> -->

  <!-- Cards 
  <section class="card-section">

    <div class="mr-auto ml-auto d-flex flex-wrap" style="max-width: 1200px;">

      <future-card
        title="Card Title 1"
        description="My Description, this is a really long sentence, omg wow haha"
        cardImg="card1.jpg">
      </future-card>

      <future-card
        title="Card Title 2"
        description="My Description, this is a really long sentence, omg wow haha"
        cardImg="card2.jpg">
      </future-card>

      <future-card
        title="Card Title 3"
        description="My Description, this is a really long sentence, omg wow haha"
        cardImg="card1.jpg">
      </future-card>

        <future-card
        title="Card Title 4"
        description="My Description, this is a really long sentence, omg wow haha"
        cardImg="card1.jpg">
      </future-card>

    </div>

  </section> -->

  <!-- Donate (OLD) -->
  <!-- 
  <section class="donate-bg-cover" style="background-image: url(/assets/img/img03.jpg);">
    <div class="container donate-inner">
      <div class="text-center text-white">
        <h1 class="font-lato">This is a <span class="font-weight-bold secondary-txt-color">NOT-FOR-PROFIT</span> Endeavour</h1>
        <p class="latofont font-16">100% of your donation goes towards costs associated with the project.</p>
        <a href="#" class="btn btn-danger donate-btn smooth btn-lg font-weight-bold text-uppercase">DONATE</a>
      </div>
    </div>
  </section>
  -->

    <!-- Donate
    <section class="more-info-section">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <h1 class="font-weight-bold text-center"><span class="secondary-txt-color">Support ‘Our Future’ initiative! </span></h1>
            <div>
              <hr class="seperator">
            </div>
            <blockquote class="otro-blockquote">
              Unless someone like you cares a whole awful lot, nothing is going to get better. It's not.
              <span class="font-16">Dr. Seuss: The Lorax</span>
            </blockquote>
            <p class="graytxt latofont">
              Times are tough financially, but we greatly appreciate any donation you are able to make.
              <br>
              You can make a difference; your voice is a catalyst for creating 
              vital change to protect our Earth.
            </p>
            
            <div class="d-flex justify-content-center">
              <a href="#" class="btn btn-danger donate-btn smooth btn-lg font-weight-bold text-uppercase">DONATE</a>
              <div>
               
                <a href="https://www.gofundme.com/">
                  <img class="mw-100 border-radius-6px" src="assets/img/fundmeBtn.png">
                </a>
               
                <a href="https://www.gofundme.com/">
                  <p class="text-center font-weight-bold">Click Here To Donate!</p>
                </a>
              </div>
            </div>
          </div>
          
          <div class="col-lg-5 d-flex justify-content-center">
            <div class="padding-6px max-width-300px">
              <img class="mw-100 border-radius-6px" src="assets/img/donate_main.png">
            </div>
          </div>
        </div>
      </div>
    </section>
  -->

  <!-- Some Information -->
  <!-- 
  <section class="more-info-section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <h1 class="font-weight-bold text-center">Our <span class="secondary-txt-color">Earth</span></h1>
          <div>
            <hr class="seperator">
          </div>
          <p class="graytxt latofont">One planet of which everyone is a part - every animal, every plant, every living thing.
            It is our home.
            It is our responsibility to understand, to respect, and to protect it.</p>
        </div>
        <div class="col-lg-7">
          <div>
            <img class="mw-100 border-radius-6px" src="assets/img/box.jpg">
          </div>
        </div>
      </div>
    </div>
  </section>
-->


  <!-- The Modal 
  <div id="myModal" class="modal" style="display: block !important" *ngIf="modalVisibility" (click)=closeModal()>


    <span (click)=closeModal() class="close">&times;</span>


    <img class="modal-content" [src]=pictureSrc id="img01">


    <div id="caption"></div>

  </div>-->

</main>