<div class="card-div">

  <div class="card-inner">

    <div class="w-100 d-flex">
      <div class="d-flex w-100 flex-wrap">
  
        <!-- Icon -->
        <div class="w-100 d-flex justify-content-center icon-div">
          <img class="icon-size" src="assets/img/1.png"> 
        </div>
  
        <!-- Text-->
        <div class="w-100 font-asap text-center">
          <h5>{{title}}</h5>
          <p class="txt-gray">{{description}}</p>
        </div>
  
      </div>
    </div>

  </div>

</div>

<!--

  [ngStyle]=
"
{
    'background-image': 'url(' + '../../../../assets/img/' + cardImg + ')'
}
"

-->