Our plan is to produce 10,000 Activity Books - and we have done this! The objective is to distribute them (each with a postcard attached) as widely as possible.
<br>
These will be handed out free to young people at markets, shows, schools etc. 
<br><br>
It is hoped, through the continued influx of postcards reaching the Prime Minister’s office from all over the country, that he will hear young people 
<br>
want him to take immediate action to prioritise renewable energies by greatly expanding the production of solar and wind power energy - moving away from fossil fuels. 
<br><br>
The distribution of Activity Books may also generate publicity that continues to highlight the ambition of young people to 
<br>
take immediate action on this important issue. In the event that we receive sufficient funds, we will use these to produce more Activity Books.

