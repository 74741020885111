import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ac-6',
  templateUrl: './ac-6.component.html',
  styleUrls: ['./ac-6.component.scss']
})
export class Ac6Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
