<div>

  <!-- Contact Us Header -->
  <div class="contact-div d-flex justify-content-center w-100" style="background-image: url('/assets/img/forest-bg.jpg')">
    <span class="contact-txt font-weight-bold text-center latofont">CONTACT US</span>
  </div>

  <!-- Email Box -->
  <div class="email-div w-100 d-inline justify-content-center">
    <div class="w-100 p-4 d-flex justify-content-center">
      <span class="text-center">Have any questions? Send us an email!</span>
    </div>
    <div class="w-75 email-box d-flex justify-content-center m-auto">
      <img class="gmail-icon" src="assets/img/gmail.svg">
      <span class="font-avenirNext email-txt">SHH@gmail.com</span>
    </div>
  </div>

</div>