We would like to thank Stefan, Lucy, Kara, Piet, Heather and Kylie for their valuable input – you have all been so generous of your time
<br>
and talent.

 We would also like to thank the following for the use of their great images:
<br>

Postcard design:
Starry night Sky - Neven Krcmarek (Unsplash)
<br>
Koala - Louise O’Brien, WIRES (NSW Wildlife Information, Rescue and Education Service Inc).
<br>
Kangaroo - Mark Galer (Unsplash)
<br>
EARTH  http://clipart-library.com/cartoon-pictures-of-the-earth.html
<br>
Beach - Walkerssk (Pixabay)
<br>
Fish on reef - Bruno Glätsch (Pixabay)
<br>
Waterfall - pieonane (Pixabay)
<br>
Cracked earth - Marion Wunder (Pixabay)
<br>
Whale - ArtTower (Pixabay)
<br>
Bleached Reef with Diver Heron island, Great Barrier Reef -
The ocean agency / xl catlin seaview survey, https://www.coralreefimagebank.org/coral-bleaching
<br>
Fire - Glenn Daniels.
<br>
Climate activists (gallery) Markus Spiske
<br>
Website (steps) - Sasin Tipchai (Pixaby)
<br>
Activity booklet:

‘Spot the Difference’ - Adrienne Lohrey.  
Dot-to-Dot https://www.raisingourkids.com/
Autosterogram https://www.easystereogrambuilder.com/
Puzzles (Wordsearch/Jiggered/Wordfit) -Doug Hendry The Puzzle Company NZ
Additional images (Clipart)