‘The Wump World’ by Bill Peet.
The Pollutions invade the Wump World and turn the green meadows into a concrete jungle.
<br><br>
‘The Lorax’ by Dr Seuss.
The Lorax is an Eco-Warrior and his message still rings loudly today in this fable about the dangers
<br>
of destroying our forests, told in the trademark style of the engaging Dr Seuss.
<br><br>
<b>‘Albert, the Victorian Door’</b> by James (Jim) Bird. 
A heart-warming tale of rescue and renovation. 
<br><br>
<a type="button" href="assets/downloads/Albert_the_Victorian_Door.pdf" class="btn btn-primary font-weight-bold dlbtn-div">
    <img class="activty-dlbutton" src="assets/img/1.png">
    Download
</a>