import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ac-1',
  templateUrl: './ac-1.component.html',
  styleUrls: ['./ac-1.component.scss']
})
export class Ac1Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
