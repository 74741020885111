<div>
  <div class="d-flex accord-header-bg accordian-header-padding"
    [attr.id]="'heading' + id"
    data-toggle="collapse"
    type="button"
    [attr.data-target]="'#collapse' + id" aria-expanded="true"
    [attr.aria-controls]="'collapse' + id">
      <p class="mb-0 text-white">
        {{title}}
    </p>
    <img class="accordian-plus-icon ml-auto" src="assets/img/add.svg">
  </div>

  <div [attr.id]="'collapse' + id" class="collapse" [attr.aria-labelledby]="'heading' + id" data-parent="#accordion">
      <div class="card-body">
        <ng-content></ng-content>
          <!-- <p class="wrap-txt">{{description}}</p> -->
      </div>
  </div>
</div>