import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  modalVisibility: Boolean = false

  pictureSrc: String = ''

  constructor() { }

  ngOnInit() {}

  openPicture(src) {
    this.pictureSrc = src
    this.modalVisibility = true;
  }

  closeModal() {
    this.modalVisibility = false;
  }

}
