We believe that an activity book is a great way to connect with young people.
<br>
Each 16-page book carries an environmental message. They are created to raise awareness in young people and THEY ARE FREE! 


<br><br>
The puzzles and activities are entertaining and designed to appeal for age range from 6 years to young adult; starting with activities 
<br>
for the younger ones and progressing through to activities for the older ones. A Postcard comes attached to each Activity Book. 

<br><br>

Through the postcard, we offer young people the option of expressing their concerns to the Prime Minister. The postcard carries a message asking him
<br>
to prioritise renewable energies by greatly expanding the production of solar and wind power energy and moving away from fossil fuels.


