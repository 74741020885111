import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ac-10',
  templateUrl: './ac-10.component.html',
  styleUrls: ['./ac-10.component.scss']
})
export class Ac10Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
