<p>Man-made climate change is happening due primarily to greenhouse gas emissions. <i>The result is a rapid increase in global warming.</i> 
<br>
We must recognise this and do something about it immediately - with short-term, mid-term and long-term strategies.
<br><br>
According to the United Nations Framework Convention on Climate Change (UNFCCC), the historic Paris Agreement of 12 December 2015
<br>
to combat climate change has been ratified by 189 countries to date, including Australia. ‘The aim is to strengthen the global response to
<br>
the threat of climate change by keeping a global temperature rise this century well below 2 degrees Celsius above pre-industrial levels
<br>
and to pursue efforts to limit the temperature increase even further to 1.5 degrees Celsius.’
<br><br>
As you can see from the NASA graph, there has been a rise by 0.75 degrees since 1880.
<i>The Paris Agreement states that all parties must have transparency of action and report regularly on their emissions and on their implementation of efforts.</i>
<br>
The 2019 Pacific Islands Forum stated: ‘All parties to the Paris agreement will ‘Formulate and communicate mid-century long-term low
<br>
greenhouse gas emissions development strategies (at the UN Glasgow conference in November) by 2020.’
<br>
<br>
<b>Update:</b> This conference was cancelled due to Covid-19 and has been rescheduled for November 2021.
<br>
Our government has not yet communicated a ‘mid-century long-term low greenhouse emissions development strategy’.
</p>