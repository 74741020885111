import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'accordion-card',
  templateUrl: './accordion-card.component.html',
  styleUrls: ['./accordion-card.component.scss']
})
export class AccordionCardComponent implements OnInit {

  @Input() title: string = ''
  @Input() description: string = ''
  @Input() id: string = ''

  constructor() { }

  ngOnInit() {
  }

}
