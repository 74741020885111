<header>
    <div class="container">
        <div class="row justify-content-center">
            <!-- Logo -->
            <div class="header-logo col d-flex">
                <a [routerLink]="['/home']"><img class="logo-size" src="assets/img/title.png"></a>
            </div>
            <!-- Right Menu -->
            <div>
                <ul>
                    <li [routerLink]="['/home']">
                        <a class="header-link">HOME</a>
                    </li>
                    <li href="assets/img/gallery.jpg" data-fancybox="images-single">
                        <a class="header-link">GALLERY</a>
                    </li>
                    <li>
                        <a 
                        href="https://www.gofundme.com/f/climate-change-showbag"
                        class="donate-now-header-btn cursor-pointer color-white">
                            Donate Now
                        </a>
                    </li>
                    <!-- 
                    <li>
                        <a (click)="openContact()" class="header-link">CONTACT</a>
                    </li>
                    -->
                </ul>
            </div>
        </div>
    </div>
</header>