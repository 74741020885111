<!-- Element -->
<div class="position-relative inline-block">
  <img class="d-block mw-100 h-auto" src="assets/img/{{image}}">
  <div class="info-div text-center text-white">
    <h4 class="team-headerstyle text-uppercase">
      {{title}}
    </h4>
    <div class="w-90 m-auto">
      <p class="team-desc">
        <i>{{description}}</i>
      </p>
    </div>
  </div>
</div>