import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MainComponent } from './main/main.component';
import { HeaderComponent } from '../pages/header/header.component';
import { FooterComponent } from '../pages/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from '../pages/home/home.component';
import { GalleryComponent } from '../pages/gallery/gallery.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FutureCardComponent } from '../pages/home/future-card/future-card.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from '../services/token.interceptor';
import { AccordionCardComponent } from '../pages/home/accordion-card/accordion-card.component';
import { TeamCardComponent } from '../pages/home/team-card/team-card.component';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { Ac1Component } from '../pages/home/accordion-card/ac-1/ac-1.component';
import { Ac2Component } from '../pages/home/accordion-card/ac-2/ac-2.component';
import { Ac3Component } from '../pages/home/accordion-card/ac-3/ac-3.component';
import { Ac4Component } from '../pages/home/accordion-card/ac-4/ac-4.component';
import { Ac5Component } from '../pages/home/accordion-card/ac-5/ac-5.component';
import { Ac6Component } from '../pages/home/accordion-card/ac-6/ac-6.component';
import { Ac7Component } from '../pages/home/accordion-card/ac-7/ac-7.component';
import { Ac8Component } from '../pages/home/accordion-card/ac-8/ac-8.component';
import { Ac9Component } from '../pages/home/accordion-card/ac-9/ac-9.component';
import { Ac10Component } from '../pages/home/accordion-card/ac-10/ac-10.component';
import { ShowbagItemComponent } from '../pages/home/showbag-item/showbag-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog'; 
import { filter } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';


declare var gtag

@NgModule({
  declarations: [
    MainComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    GalleryComponent,
    FutureCardComponent,
    AccordionCardComponent,
    TeamCardComponent,
    ShowbagItemComponent,
    Ac1Component,
    Ac2Component,
    Ac3Component,
    Ac4Component,
    Ac5Component,
    Ac6Component,
    Ac7Component,
    Ac8Component,
    Ac9Component,
    Ac10Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgxGalleryModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule
  ],
  providers: 
  [
    
  ],
  bootstrap: [MainComponent]
})
export class AppModule { 

  constructor(router: Router) {
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', 'UA-165975639-1', {'page_path':e.urlAfterRedirects});
    });
  
  }

}
