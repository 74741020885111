<li class="media px-1 py-3 py-md-4">
  <!-- Icon -->
  <div class="mr-3">
    <img src="{{icon}}" style="width: 48px; height: 48px;">
  </div>
  <!-- Text -->
  <div class="media-body align-self-center align-self-md-start">
    <h3 class="bag-section-title">
      {{title}}
    </h3>
    <p class="bag-section-desc">
      {{desc}}
    </p>
  </div>
</li>