import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'showbag-item',
  templateUrl: './showbag-item.component.html',
  styleUrls: ['./showbag-item.component.scss']
})
export class ShowbagItemComponent implements OnInit {

  @Input() title: string = '{{title}}'
  @Input() desc: string = '{{description}}'
  @Input() icon: string = ''

  constructor() { }

  ngOnInit() {
  }

}
