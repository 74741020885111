We first started with the idea of producing an environmental showbag. With a production target of 10,000 we needed $34,000, 
<br>
so we opened up a GoFundMe page. As time went on, we were falling well short of our target, however, we were determined 
<br>
and hence decided to produce only the Activity Books with the postcards – after all they carried the message we wanted to deliver.


<br><br>
Using our own funds, and after many, many months of hard work, we now present to Australia’s young people our environmental Activity Book. 

<br><br>
Are we passionate about the environment? You bet we are!
<br><br>
Helen and Jim
<br><br>
<i>In developing this project through planning, designing and research we have willingly invested our own time and resources.
<br>
We hope the Australian public can help make Our Future Activity book a reality! Please donate though our GoFundMe campaign.</i>




