import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ac-7',
  templateUrl: './ac-7.component.html',
  styleUrls: ['./ac-7.component.scss']
})
export class Ac7Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
